import React, { createContext, useContext, useState, useEffect } from "react";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const getLocalStorageData = localStorage.getItem("userData");
    try {
      const parsedData = JSON.parse(getLocalStorageData);
      console.log("UserData: ",parsedData);
      setUserData(parsedData);
    } catch (error) {
      console.error("Failed to parse userData from localStorage:", error);
      setUserData(null);
    }
  }, []);

  return (
    <DataContext.Provider value={{ userData, setUserData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
