import { SvgIcon } from "@mui/material";

export default function MileStone(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.997 11.521H4.5C3.6715 11.521 3 12.193 3 13.021V13.9955C3 14.824 3.6715 15.4955 4.5 15.4955H8.4965C9.325 15.4955 9.9965 14.824 9.9965 13.9955V13.021C9.9965 12.193 9.325 11.521 8.4965 11.521H6.997L6.9915 7.508H12.4955C12.6875 7.508 12.863 7.398 12.946 7.2245C13.0295 7.0515 13.006 6.846 12.886 6.696L11.1355 4.505L12.886 2.314C13.006 2.164 13.0295 1.958 12.946 1.785C12.863 1.612 12.6875 1.502 12.4955 1.502H6.9825L6.982 0.999001C6.9815 0.723501 6.757 0.499501 6.481 0.500001C6.2055 0.500501 5.9815 0.724501 5.982 1.0005L5.997 11.521ZM8.9965 13.021V13.9955C8.9965 14.2715 8.773 14.4955 8.4965 14.4955H4.5C4.224 14.4955 4 14.2715 4 13.9955V13.021C4 12.745 4.224 12.521 4.5 12.521H8.4965C8.773 12.521 8.9965 12.745 8.9965 13.021ZM6.99 6.508H11.456L10.105 4.817C9.959 4.6345 9.959 4.3755 10.105 4.193L11.456 2.502H6.984L6.99 6.508Z"
          fill="#8E97A7"
        />
      </svg>
    </SvgIcon>
  );
}
