const fr = {
  donthaveaccount: `Vous n'avez pas de compte ?`,
  getstarted: "Commencer",
  welcomeback: "Salut, bienvenue à nouveau",
  signin: `S'identifier`,
  enterdetails: "Entrez vos coordonnées ci-dessous.",
  username: `Nom d'utilisateur`,
  password: "Mot de passe",
  confirmpassword: "Confirmez le mot de passe",
  changepassword: "Changer le mot de passe",
  currentpassword: "Mot de passe actuel",
  newpassword: "Nouveau mot de passe",
  confirmnewpassword: "Confirmer le nouveau mot de passe",
  rememberme: "Souviens-toi de moi",
  forgotpassword: "mot de passe oublié?",
  login: "Connexion",
  or: "ou",
  and: "et",
  register: `S'inscrire`,
  alreadyhaveaccount: "Vous avez déjà un compte?",
  agreement: `En m'inscrivant, j'accepte Matchpoint Solutions`,
  termservice: `Conditions d'utilisation`,
  privacypolicy: "Politique de confidentialité",
  firstname: "Prénom",
  lastname: "Nom de famille",
  mobile: "Numéro de portable",
  email: "E-mail",
  emailaddress: "Adresse e-mail",
  dashboard: "Tableau de bord",
  user: "Utilisateur",
  users: "Utilisateurs",
  role: "Rôle",
  roles: "Les rôles",
  profile: "Profil",
  logout: "Se déconnecter",
  list: "Liste",
  new: "Nouvel",
  save: "Enregistrer",
  edit: "Modifier",
  delete: "Supprimer",
  submit: "Soumettre",
  cancel: "Annuler",
  upload: "Télécharger",
  photo: "Photo",
  theme: "Thème",
  language: "Langue",
  settings: "Réglages",
  themepreference: "Préférence de thème",
  languagepreference: "Préférence de langue",
  allowed: "Permis",
  createrole: "Créer un nouveau rôle",
  rolename: "Nom de rôle",
  roledesc: "Description du rôle",
  createuser: "Créer un nouvel utilisateur",
  permissions: "Autorisations",
  permissiondesc: "Veuillez sélectionner les autorisations",
  payment: 'Paiement',
  paymentMessage: {
    success: 'Transaction terminée avec succès !',
    error: `Quelque chose s'est mal passé, veuillez réessayer !`,
    cancel: `Transaction annulée par l'utilisateur.`,
  },
  status: 'Statut',
  transactionid: 'Identifiant de transaction',
  validation: {
    required: "est requis",
    minlength: "doit contenir 8 caractères",
    maxlength: "ne doit pas dépasser 120 caractères",
    password:
      "Le mot de passe doit avoir au moins une majuscule, un caractère spécial, un chiffre",
    match: "doit correspondre",
    valid: "Veuillez entrer valide",
    onlyalpha: "ne doit contenir que des alphabets",
    username: "Nom d'utilisateur autorisé caractères alphanumériques _ + . - @",
  },
  message: {
    create: "créé avec succès !",
    save: "enregistré avec succès !",
    delete: "Supprimé avec succès !",
    change: "changé avec succès !",
    update: "mis à jour avec succès !",
    deleteconfirm: "Êtes-vous sûr de vouloir supprimer ceci ?",
    maxsize: "taille maximale de",
  },
};

export default fr;
