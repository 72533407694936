import { SvgIcon } from "@mui/material";

export default function Contracts(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.3488 3.44185V12.5581C14.3488 13.9067 13.2555 15 11.9069 15H4.09298C2.74442 15 1.65112 13.9067 1.65112 12.5581V3.44185C1.65112 2.0933 2.74442 1 4.09298 1H11.9069C13.2555 1 14.3488 2.0933 14.3488 3.44185ZM13.372 3.44185C13.372 2.63246 12.7163 1.97674 11.9069 1.97674H4.09298C3.28358 1.97674 2.62786 2.63246 2.62786 3.44185V12.5581C2.62786 13.3675 3.28358 14.0232 4.09298 14.0232H11.9069C12.7163 14.0232 13.372 13.3675 13.372 12.5581V3.44185Z"
          fill="#8E97A7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.99979 4.60468C8.26937 4.60468 8.48816 4.82347 8.48816 5.09305C8.48816 5.36263 8.26937 5.58142 7.99979 5.58142H4.74399C4.47441 5.58142 4.25562 5.36263 4.25562 5.09305C4.25562 4.82347 4.47441 4.60468 4.74399 4.60468H7.99979Z"
          fill="#8E97A7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.2556 7.20929C11.5252 7.20929 11.744 7.42808 11.744 7.69766C11.744 7.96724 11.5252 8.18603 11.2556 8.18603H4.74399C4.47441 8.18603 4.25562 7.96724 4.25562 7.69766C4.25562 7.42808 4.47441 7.20929 4.74399 7.20929H11.2556Z"
          fill="#8E97A7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.9997 10C11.2693 10 11.4881 10.2188 11.4881 10.4884C11.4881 10.758 11.2693 10.9767 10.9997 10.9767H4.48813C4.21855 10.9767 3.99976 10.758 3.99976 10.4884C3.99976 10.2188 4.21855 10 4.48813 10H10.9997Z"
          fill="#8E97A7"
        />
      </svg>
    </SvgIcon>
  );
}
