import { SvgIcon } from "@mui/material";

export default function MenuInvoicing(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3499 2.94V12.06C13.3499 13.41 12.2599 14.5 10.9099 14.5H3.0899C1.7399 14.5 0.649902 13.41 0.649902 12.06V2.94C0.649902 1.59 1.7399 0.5 3.0899 0.5H10.8999C12.2499 0.5 13.3399 1.59 13.3399 2.94H13.3499ZM12.3699 2.94C12.3699 2.13 11.7099 1.47 10.8999 1.47H3.0899C2.2799 1.47 1.6199 2.13 1.6199 2.94V12.06C1.6199 12.87 2.2799 13.53 3.0899 13.53H10.8999C11.7099 13.53 12.3699 12.87 12.3699 12.06V2.94Z"
          fill="#8E97A7"
        />
        <path
          d="M6.99373 9.74888C6.42967 9.74888 5.95334 9.36507 5.95334 8.8973C5.95334 8.62144 5.69011 8.41754 5.35167 8.41754C5.01323 8.41754 4.75 8.64543 4.75 8.8973C4.75 9.7009 5.38928 10.4085 6.32939 10.6244V11.0202C6.32939 11.2961 6.59262 11.5 6.93106 11.5C7.2695 11.5 7.53273 11.2721 7.53273 11.0202V10.6364C8.62326 10.4085 9.36281 9.55697 9.2124 8.64543C9.06198 7.73388 8.12187 7.03823 7.00627 7.03823C6.4422 7.03823 5.96588 6.65442 5.96588 6.18666C5.96588 5.71889 6.42967 5.33508 7.00627 5.33508C7.58287 5.33508 8.04666 5.71889 8.04666 6.18666C8.04666 6.46252 8.30989 6.66642 8.64833 6.66642C8.98677 6.66642 9.25 6.43853 9.25 6.18666C9.25 5.35907 8.54805 4.62744 7.5578 4.42354V3.97976C7.5578 3.7039 7.29457 3.5 6.95613 3.5C6.61769 3.5 6.35446 3.72789 6.35446 3.97976V4.45952C5.30153 4.71139 4.62465 5.58696 4.80014 6.46252C4.96309 7.35008 5.9032 7.99775 7.00627 7.99775C7.57033 7.99775 8.05919 8.36957 8.05919 8.82534C8.05919 9.28111 7.60794 9.67691 7.0188 9.67691V9.72489L6.99373 9.74888Z"
          fill="#8E97A7"
        />
      </svg>
    </SvgIcon>
  );
}
