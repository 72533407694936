import { SvgIcon } from "@mui/material";

export default function Pricing(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.945 2.671H12.77V2.5C12.77 1.425 11.895 0.55 10.82 0.55H3.05498C1.97998 0.55 1.10498 1.425 1.10498 2.5V11.335C1.10498 12.41 1.97998 13.285 3.05498 13.285H3.22398V13.5C3.22398 14.575 4.09898 15.45 5.17398 15.45H12.945C14.02 15.45 14.895 14.575 14.895 13.5V4.621C14.895 3.546 14.02 2.671 12.945 2.671ZM3.05498 12.385C2.47598 12.385 2.00498 11.9145 2.00498 11.335V2.5C2.00498 1.921 2.47548 1.45 3.05498 1.45H10.82C11.399 1.45 11.87 1.921 11.87 2.5V2.671H6.34848C5.96098 2.671 5.59648 2.822 5.32248 3.096L3.64898 4.7705C3.37498 5.0445 3.22398 5.4085 3.22398 5.7955V12.385H3.05498ZM6.58648 3.571V5.487C6.58648 5.79 6.33998 6.037 6.03648 6.037H4.12448V5.7955C4.12448 5.649 4.18148 5.5105 4.28548 5.4065L5.95948 3.7325C6.06348 3.6285 6.20148 3.5715 6.34848 3.5715L6.58648 3.571ZM13.9945 13.5C13.9945 14.079 13.524 14.55 12.9445 14.55H5.17448C4.59548 14.55 4.12448 14.0795 4.12448 13.5V6.937H6.03648C6.83598 6.937 7.48648 6.2865 7.48648 5.487V3.571H12.945C13.524 3.571 13.995 4.042 13.995 4.621L13.9945 13.5Z"
          fill="#8E97A7"
        />
        <path
          d="M9.69438 10.9678C9.19299 10.9678 8.76959 10.6319 8.76959 10.2227C8.76959 9.98127 8.53561 9.80286 8.23477 9.80286C7.93393 9.80286 7.69995 10.0023 7.69995 10.2227C7.69995 10.9258 8.2682 11.545 9.10385 11.7339V12.0802C9.10385 12.3216 9.33783 12.5 9.63867 12.5C9.93951 12.5 10.1735 12.3006 10.1735 12.0802V11.7444C11.1428 11.545 11.8002 10.7999 11.6665 10.0023C11.5328 9.20466 10.6972 8.59596 9.70552 8.59596C9.20413 8.59596 8.78073 8.26013 8.78073 7.85084C8.78073 7.44154 9.19299 7.10571 9.70552 7.10571C10.2181 7.10571 10.6303 7.44154 10.6303 7.85084C10.6303 8.09222 10.8643 8.27063 11.1651 8.27063C11.466 8.27063 11.7 8.07123 11.7 7.85084C11.7 7.1267 11.076 6.48652 10.1958 6.30811V5.9198C10.1958 5.67842 9.96179 5.50001 9.66095 5.50001C9.36012 5.50001 9.12614 5.69941 9.12614 5.9198V6.33959C8.1902 6.55998 7.58853 7.3261 7.74452 8.09222C7.88937 8.86883 8.72502 9.43554 9.70552 9.43554C10.2069 9.43554 10.6415 9.76088 10.6415 10.1597C10.6415 10.5585 10.2403 10.9048 9.71666 10.9048V10.9468L9.69438 10.9678Z"
          fill="#8E97A7"
        />
      </svg>
    </SvgIcon>
  );
}
