import { SvgIcon } from "@mui/material";
export default function EffortEstimation(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14 6.02324C14 6.29282 13.7812 6.51161 13.5116 6.51161C13.242 6.51161 13.0233 6.29282 13.0233 6.02324V2.60463C13.0233 2.38845 12.9373 2.18203 12.7849 2.029C12.6319 1.87663 12.4255 1.79068 12.2093 1.79068H1.7907C1.57451 1.79068 1.36809 1.87663 1.21507 2.029C1.0627 2.18203 0.976744 2.38845 0.976744 2.60463V11.7209C0.976744 11.9371 1.0627 12.1435 1.21507 12.2965C1.36809 12.4489 1.57451 12.5349 1.7907 12.5349H5.69767C5.96726 12.5349 6.18605 12.7537 6.18605 13.0232C6.18605 13.2928 5.96726 13.5116 5.69767 13.5116H1.7907C1.316 13.5116 0.860186 13.3228 0.524186 12.9874C0.188837 12.6514 0 12.1956 0 11.7209V2.60463C0 2.12993 0.188837 1.67412 0.524186 1.33812C0.860186 1.00277 1.316 0.813934 1.7907 0.813934H12.2093C12.684 0.813934 13.1398 1.00277 13.4758 1.33812C13.8112 1.67412 14 2.12993 14 2.60463V6.02324Z"
          fill="#8E97A7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.7441 2.11628C10.7441 2.38586 10.5253 2.60465 10.2557 2.60465C9.98612 2.60465 9.76733 2.38586 9.76733 2.11628V0.488372C9.76733 0.218791 9.98612 0 10.2557 0C10.5253 0 10.7441 0.218791 10.7441 0.488372V2.11628Z"
          fill="#8E97A7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.2326 2.11628C4.2326 2.38586 4.01381 2.60465 3.74423 2.60465C3.47465 2.60465 3.25586 2.38586 3.25586 2.11628V0.488372C3.25586 0.218791 3.47465 0 3.74423 0C4.01381 0 4.2326 0.218791 4.2326 0.488372V2.11628Z"
          fill="#8E97A7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.488372 4.72091C0.218791 4.72091 0 4.50212 0 4.23254C0 3.96296 0.218791 3.74417 0.488372 3.74417H13.5116C13.7812 3.74417 14 3.96296 14 4.23254C14 4.50212 13.7812 4.72091 13.5116 4.72091H0.488372Z"
          fill="#8E97A7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.2557 6.51165C12.3225 6.51165 13.9998 8.18904 13.9998 10.2558C13.9998 12.3226 12.3225 14 10.2557 14C8.18887 14 6.51147 12.3226 6.51147 10.2558C6.51147 8.18904 8.18887 6.51165 10.2557 6.51165ZM10.2557 7.48839C8.72803 7.48839 7.48822 8.72821 7.48822 10.2558C7.48822 11.7835 8.72803 13.0233 10.2557 13.0233C11.7833 13.0233 13.0231 11.7835 13.0231 10.2558C13.0231 8.72821 11.7833 7.48839 10.2557 7.48839Z"
          fill="#8E97A7"
        />
        <path
          d="M11.2977 10.9075C11.3022 10.2965 10.8265 9.79804 10.2412 9.79804C10.0672 9.79804 9.92711 9.64428 9.93944 9.46037C9.94986 9.30513 10.0706 9.17939 10.2195 9.16855C10.3358 9.16009 10.4395 9.2205 10.4967 9.31451C10.5596 9.41779 10.6666 9.48289 10.784 9.48289H10.7976C11.0651 9.48289 11.2285 9.17708 11.088 8.93999C10.9516 8.70988 10.7313 8.53929 10.4718 8.47484V8.41244C10.4718 8.27969 10.3686 8.17207 10.2411 8.17207C10.1137 8.17207 10.0104 8.27969 10.0104 8.41244V8.47485C9.57289 8.58353 9.24657 8.99421 9.24658 9.48291C9.24659 10.0543 9.69274 10.5192 10.2411 10.5192C10.4514 10.5192 10.6206 10.7056 10.6046 10.9281C10.5912 11.1138 10.4474 11.2636 10.2692 11.2776C10.1203 11.2892 9.98834 11.2072 9.92197 11.0822C9.86155 10.9684 9.74552 10.8989 9.62073 10.8989C9.35675 10.8989 9.1895 11.1949 9.31843 11.4349C9.46314 11.7043 9.71342 11.904 10.0104 11.9732V12.034C10.0104 12.1668 10.1137 12.2744 10.2411 12.2744C10.3686 12.2744 10.4718 12.1668 10.4718 12.034V11.9731C10.9405 11.8633 11.2939 11.4268 11.2977 10.9075Z"
          fill="#8E97A7"
        />
      </svg>
    </SvgIcon>
  );
}
