import { SvgIcon } from "@mui/material";

export default function MenuTimeSheet(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M5 9C5.27614 9 5.5 8.77614 5.5 8.5C5.5 8.22386 5.27614 8 5 8C4.72386 8 4.5 8.22386 4.5 8.5C4.5 8.77614 4.72386 9 5 9Z"
          fill="#8E97A7"
        />
        <path
          d="M5 11C5.27614 11 5.5 10.7761 5.5 10.5C5.5 10.2239 5.27614 10 5 10C4.72386 10 4.5 10.2239 4.5 10.5C4.5 10.7761 4.72386 11 5 11Z"
          fill="#8E97A7"
        />
        <path
          d="M5 13C5.27614 13 5.5 12.7761 5.5 12.5C5.5 12.2239 5.27614 12 5 12C4.72386 12 4.5 12.2239 4.5 12.5C4.5 12.7761 4.72386 13 5 13Z"
          fill="#8E97A7"
        />
        <path
          d="M8 9C8.27614 9 8.5 8.77614 8.5 8.5C8.5 8.22386 8.27614 8 8 8C7.72386 8 7.5 8.22386 7.5 8.5C7.5 8.77614 7.72386 9 8 9Z"
          fill="#8E97A7"
        />
        <path
          d="M8 11C8.27614 11 8.5 10.7761 8.5 10.5C8.5 10.2239 8.27614 10 8 10C7.72386 10 7.5 10.2239 7.5 10.5C7.5 10.7761 7.72386 11 8 11Z"
          fill="#8E97A7"
        />
        <path
          d="M8 13C8.27614 13 8.5 12.7761 8.5 12.5C8.5 12.2239 8.27614 12 8 12C7.72386 12 7.5 12.2239 7.5 12.5C7.5 12.7761 7.72386 13 8 13Z"
          fill="#8E97A7"
        />
        <path
          d="M11 9C11.2761 9 11.5 8.77614 11.5 8.5C11.5 8.22386 11.2761 8 11 8C10.7239 8 10.5 8.22386 10.5 8.5C10.5 8.77614 10.7239 9 11 9Z"
          fill="#8E97A7"
        />
        <path
          d="M11 11C11.2761 11 11.5 10.7761 11.5 10.5C11.5 10.2239 11.2761 10 11 10C10.7239 10 10.5 10.2239 10.5 10.5C10.5 10.7761 10.7239 11 11 11Z"
          fill="#8E97A7"
        />
        <path
          d="M11 13C11.2761 13 11.5 12.7761 11.5 12.5C11.5 12.2239 11.2761 12 11 12C10.7239 12 10.5 12.2239 10.5 12.5C10.5 12.7761 10.7239 13 11 13Z"
          fill="#8E97A7"
        />
        <path
          d="M13 2.5H12V2C12 1.7 11.8 1.5 11.5 1.5C11.2 1.5 11 1.7 11 2V2.5H5V2C5 1.7 4.8 1.5 4.5 1.5C4.2 1.5 4 1.7 4 2V2.5H3C1.9 2.5 1 3.4 1 4.5V13.5C1 14.6 1.9 15.5 3 15.5H13C14.1 15.5 15 14.6 15 13.5V4.5C15 3.4 14.1 2.5 13 2.5ZM3 3.5H4V4C4 4.3 4.2 4.5 4.5 4.5C4.8 4.5 5 4.3 5 4V3.5H11V4C11 4.3 11.2 4.5 11.5 4.5C11.8 4.5 12 4.3 12 4V3.5H13C13.55 3.5 14 3.95 14 4.5V5.5H2V4.5C2 3.95 2.45 3.5 3 3.5ZM13 14.5H3C2.45 14.5 2 14.05 2 13.5V6.5H14V13.5C14 14.05 13.55 14.5 13 14.5Z"
          fill="#8E97A7"
        />
      </svg>
    </SvgIcon>
  );
}
