import { SvgIcon } from "@mui/material";

export default function MenuRole(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.67442 12.8806H1.10698C1.07247 12.8806 1.03926 12.8663 1.01516 12.8422C0.990419 12.8174 0.976744 12.7849 0.976744 12.7503V11.8055C0.976744 11.265 1.36288 10.7884 1.95805 10.3834C3.02074 9.65797 4.74047 9.20086 6.67442 9.20086C6.944 9.20086 7.16279 8.98207 7.16279 8.71249C7.16279 8.44356 6.944 8.22411 6.67442 8.22411C4.51126 8.22411 2.59619 8.76523 1.40716 9.57593C0.500093 10.1945 0 10.9805 0 11.8055V12.7503C0 13.0434 0.116558 13.3253 0.324279 13.533C0.532 13.7401 0.813302 13.8573 1.10698 13.8573H6.67442C6.944 13.8567 7.16279 13.6379 7.16279 13.3683C7.16279 13.0994 6.944 12.8799 6.67442 12.8806Z"
          fill="#8E97A7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.67417 0.5C4.69724 0.5 3.09277 2.10447 3.09277 4.0814C3.09277 6.05833 4.69724 7.66279 6.67417 7.66279C8.6511 7.66279 10.2556 6.05833 10.2556 4.0814C10.2556 2.10447 8.6511 0.5 6.67417 0.5ZM6.67417 1.47674C8.11194 1.47674 9.27882 2.64363 9.27882 4.0814C9.27882 5.51916 8.11194 6.68605 6.67417 6.68605C5.2364 6.68605 4.06952 5.51916 4.06952 4.0814C4.06952 2.64363 5.2364 1.47674 6.67417 1.47674Z"
          fill="#8E97A7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5812 8.76197C9.30097 8.76197 8.26172 9.80122 8.26172 11.0814C8.26172 12.3616 9.30097 13.4015 10.5812 13.4015C11.8613 13.4015 12.9013 12.3616 12.9013 11.0814C12.9013 9.80122 11.8613 8.76197 10.5812 8.76197ZM10.5812 9.73871C11.3222 9.73871 11.9245 10.3404 11.9245 11.0814C11.9245 11.8224 11.3222 12.4248 10.5812 12.4248C9.84014 12.4248 9.23781 11.8224 9.23781 11.0814C9.23781 10.3404 9.84014 9.73871 10.5812 9.73871Z"
          fill="#8E97A7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0695 9.24965V8.15114C11.0695 7.88156 10.8507 7.66277 10.5811 7.66277C10.3116 7.66277 10.0928 7.88156 10.0928 8.15114V9.2503C10.0928 9.51923 10.3116 9.73868 10.5811 9.73868C10.8507 9.73803 11.0695 9.51923 11.0695 9.24965Z"
          fill="#8E97A7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2212 10.132L12.998 9.3545C13.1888 9.16436 13.1888 8.85441 12.998 8.66427C12.8079 8.47348 12.4979 8.47348 12.3078 8.66427L11.5303 9.44111C11.3402 9.6319 11.3402 9.9412 11.5303 10.132C11.7211 10.3221 12.0304 10.3221 12.2212 10.132Z"
          fill="#8E97A7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4125 11.5697H13.5117C13.7813 11.5697 14.0001 11.351 14.0001 11.0814C14.0001 10.8118 13.7813 10.593 13.5117 10.593H12.4125C12.1436 10.593 11.9248 10.8118 11.9248 11.0814C11.9242 11.351 12.1436 11.5697 12.4125 11.5697Z"
          fill="#8E97A7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5303 12.7217L12.3078 13.4985C12.4979 13.6893 12.8079 13.6893 12.998 13.4985C13.1888 13.3084 13.1888 12.9984 12.998 12.8083L12.2212 12.0308C12.0304 11.8407 11.7211 11.8407 11.5303 12.0308C11.3402 12.2216 11.3402 12.5309 11.5303 12.7217Z"
          fill="#8E97A7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0928 12.9125V14.0116C10.0928 14.2812 10.3116 14.5 10.5811 14.5C10.8507 14.5 11.0695 14.2812 11.0695 14.0116V12.9125C11.0695 12.6435 10.8507 12.4248 10.5811 12.4241C10.3116 12.4241 10.0928 12.6435 10.0928 12.9125Z"
          fill="#8E97A7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.94141 12.0308L8.16458 12.8083C7.97379 12.9984 7.97379 13.3084 8.16458 13.4985C8.35472 13.6893 8.66467 13.6893 8.85481 13.4985L9.6323 12.7217C9.82244 12.5309 9.82244 12.2216 9.6323 12.0308C9.44151 11.8407 9.13221 11.8407 8.94141 12.0308Z"
          fill="#8E97A7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.74997 10.593H7.65146C7.38188 10.593 7.16309 10.8118 7.16309 11.0814C7.16309 11.351 7.38188 11.5697 7.65146 11.5697H8.74997C9.01955 11.5697 9.23899 11.351 9.23834 11.0814C9.23834 10.8118 9.01955 10.593 8.74997 10.593Z"
          fill="#8E97A7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.6323 9.44111L8.85481 8.66427C8.66467 8.47348 8.35472 8.47348 8.16458 8.66427C7.97379 8.85441 7.97379 9.16436 8.16458 9.3545L8.94141 10.132C9.13221 10.3221 9.44151 10.3221 9.6323 10.132C9.82244 9.9412 9.82244 9.6319 9.6323 9.44111Z"
          fill="#8E97A7"
        />
      </svg>
    </SvgIcon>
  );
}
