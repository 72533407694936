import { SvgIcon } from "@mui/material";

export default function MenuClient(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M10.8228 7.52417C12.1099 7.52417 13.1567 6.47705 13.1567 5.18994C13.1567 3.90283 12.1099 2.85571 10.8228 2.85571C9.53564 2.85571 8.48828 3.90283 8.48828 5.18994C8.48828 6.47705 9.53564 7.52417 10.8228 7.52417ZM12.1567 5.18994C12.1567 5.92554 11.5581 6.52417 10.8228 6.52417C10.0869 6.52417 9.48828 5.92554 9.48828 5.18994C9.48828 4.45435 10.0869 3.85571 10.8228 3.85571C11.5581 3.85571 12.1567 4.45435 12.1567 5.18994Z"
          fill="white"
        />
        <path
          d="M10.8248 8.27417C10.3541 8.27417 9.90394 8.34961 9.48646 8.49829C9.22669 8.59082 9.09095 8.87695 9.18324 9.13696C9.26795 9.37436 9.51304 9.49896 9.75388 9.45001C9.7768 9.44534 9.79969 9.44824 9.8224 9.44019C10.1315 9.33008 10.4689 9.27417 10.8248 9.27417C11.6744 9.27417 12.4723 9.604 13.0709 10.2026C13.6696 10.8015 13.9996 11.5994 13.9996 12.4492C13.9996 12.635 13.9274 12.8093 13.796 12.9409C13.6647 13.072 13.4923 13.1443 13.3097 13.1443H10.6198C10.5846 13.1443 10.5537 13.1574 10.5206 13.1643C10.2936 13.2116 10.1198 13.4033 10.1198 13.6443C10.1198 13.9204 10.3434 14.1443 10.6198 14.1443H13.3097C13.7594 14.1443 14.1832 13.9678 14.5031 13.6479C14.8234 13.3279 14.9996 12.9023 14.9996 12.4492C14.9996 11.3323 14.5656 10.2834 13.778 9.49561C12.9904 8.70801 11.9415 8.27417 10.8248 8.27417Z"
          fill="white"
        />
        <path
          d="M5.17676 7.52417C6.46387 7.52417 7.51123 6.47705 7.51123 5.18994C7.51123 3.90283 6.46387 2.85571 5.17676 2.85571C3.88965 2.85571 2.84277 3.90283 2.84277 5.18994C2.84277 6.47705 3.88965 7.52417 5.17676 7.52417ZM5.17676 3.85571C5.9126 3.85571 6.51123 4.45435 6.51123 5.18994C6.51123 5.92554 5.9126 6.52417 5.17676 6.52417C4.44141 6.52417 3.84277 5.92554 3.84277 5.18994C3.84277 4.45435 4.44141 3.85571 5.17676 3.85571Z"
          fill="white"
        />
        <path
          d="M2.69141 14.1426H7.6626C8.59521 14.1426 9.354 13.3838 9.354 12.4512C9.354 10.1479 7.47998 8.27417 5.17676 8.27417C2.87354 8.27417 1 10.1479 1 12.4512C1 13.3838 1.75879 14.1426 2.69141 14.1426ZM5.17676 9.27417C6.92871 9.27417 8.354 10.6995 8.354 12.4512C8.354 12.8325 8.04395 13.1426 7.6626 13.1426H2.69141C2.31006 13.1426 2 12.8325 2 12.4512C2 10.6995 3.42529 9.27417 5.17676 9.27417Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
