import { SvgIcon } from "@mui/material";

export default function MenuAllocation(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
      >
        <path
          d="M8 5.5C9.37842 5.5 10.5 4.37842 10.5 3C10.5 1.62158 9.37842 0.5 8 0.5C6.62158 0.5 5.5 1.62158 5.5 3C5.5 4.37842 6.62158 5.5 8 5.5ZM8 1.5C8.82715 1.5 9.5 2.17285 9.5 3C9.5 3.82715 8.82715 4.5 8 4.5C7.17285 4.5 6.5 3.82715 6.5 3C6.5 2.17285 7.17285 1.5 8 1.5Z"
          fill="#8E97A7"
        />
        <path
          d="M2.75 6C3.85279 6 4.75 5.10302 4.75 4C4.75 2.89698 3.85279 2 2.75 2C1.64722 2 0.75 2.89698 0.75 4C0.75 5.10302 1.64722 6 2.75 6ZM2.75 3C3.30152 3 3.75 3.44873 3.75 4C3.75 4.55127 3.30152 5 2.75 5C2.19849 5 1.75 4.55127 1.75 4C1.75 3.44873 2.19849 3 2.75 3Z"
          fill="#8E97A7"
        />
        <path
          d="M13.25 6C14.3528 6 15.25 5.10302 15.25 4C15.25 2.89698 14.3528 2 13.25 2C12.1472 2 11.25 2.89698 11.25 4C11.25 5.10302 12.1472 6 13.25 6ZM13.25 3C13.8015 3 14.25 3.44873 14.25 4C14.25 4.55127 13.8015 5 13.25 5C12.6985 5 12.25 4.55127 12.25 4C12.25 3.44873 12.6985 3 13.25 3Z"
          fill="#8E97A7"
        />
        <path
          d="M15.1958 7.80859C14.6809 7.28711 13.9897 7 13.25 7C12.4123 7 11.6372 7.38654 11.1254 8.01047C10.3918 7.09149 9.26449 6.5 8 6.5C6.7363 6.5 5.60956 7.09067 4.87598 8.00867C4.8193 7.94013 4.76013 7.87308 4.69604 7.80908C4.20142 7.30762 3.53834 7.021 2.82862 7.00098C1.28322 6.95738 0 8.34779 0 9.85693V12C0 12.8271 0.67285 13.5 1.5 13.5H4.09216C4.29922 14.0807 4.84903 14.5 5.5 14.5H10.5C11.151 14.5 11.7008 14.0807 11.9079 13.5H14.5C15.3272 13.5 16 12.8271 16 12V9.75C16 9.01025 15.7129 8.31933 15.1958 7.80859ZM1 12C1 12 1 9.85817 1 9.85693C1 8.89294 1.81403 7.98152 2.80077 8.00097C3.43571 8.01349 4.02633 8.40492 4.30548 8.97009C4.10944 9.44177 4 9.95819 4 10.5V12.5H1.5C1.22436 12.5 1 12.2759 1 12ZM10.5 13.5H5.5C5.22437 13.5 5 13.2759 5 13V10.5C5 8.84571 6.34571 7.5 8 7.5C9.65429 7.5 11 8.84571 11 10.5V13C11 13.2759 10.7756 13.5 10.5 13.5ZM15 12C15 12.2759 14.7756 12.5 14.5 12.5H12V10.5C12 9.95578 11.8899 9.43701 11.6922 8.96366C11.9824 8.38176 12.5846 8.00001 13.25 8C14.183 7.99998 15 8.82097 15 9.75V12Z"
          fill="#8E97A7"
        />
      </svg>
    </SvgIcon>
  );
}
