import { SvgIcon } from "@mui/material";

export default function MenuEstimation(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: "57px", height: "72px" }}
      >
        <path
          d="M20.25 24.625C20.5952 24.625 20.875 24.3452 20.875 24C20.875 23.6548 20.5952 23.375 20.25 23.375C19.9048 23.375 19.625 23.6548 19.625 24C19.625 24.3452 19.9048 24.625 20.25 24.625Z"
          fill="#979797"
        />
        <path
          d="M20.25 27.125C20.5952 27.125 20.875 26.8452 20.875 26.5C20.875 26.1548 20.5952 25.875 20.25 25.875C19.9048 25.875 19.625 26.1548 19.625 26.5C19.625 26.8452 19.9048 27.125 20.25 27.125Z"
          fill="#979797"
        />
        <path
          d="M20.25 29.625C20.5952 29.625 20.875 29.3452 20.875 29C20.875 28.6548 20.5952 28.375 20.25 28.375C19.9048 28.375 19.625 28.6548 19.625 29C19.625 29.3452 19.9048 29.625 20.25 29.625Z"
          fill="#979797"
        />
        <path
          d="M24 24.625C24.3452 24.625 24.625 24.3452 24.625 24C24.625 23.6548 24.3452 23.375 24 23.375C23.6548 23.375 23.375 23.6548 23.375 24C23.375 24.3452 23.6548 24.625 24 24.625Z"
          fill="#979797"
        />
        <path
          d="M24 27.125C24.3452 27.125 24.625 26.8452 24.625 26.5C24.625 26.1548 24.3452 25.875 24 25.875C23.6548 25.875 23.375 26.1548 23.375 26.5C23.375 26.8452 23.6548 27.125 24 27.125Z"
          fill="#979797"
        />
        <path
          d="M24 29.625C24.3452 29.625 24.625 29.3452 24.625 29C24.625 28.6548 24.3452 28.375 24 28.375C23.6548 28.375 23.375 28.6548 23.375 29C23.375 29.3452 23.6548 29.625 24 29.625Z"
          fill="#979797"
        />
        <path
          d="M27.75 24.625C28.0952 24.625 28.375 24.3452 28.375 24C28.375 23.6548 28.0952 23.375 27.75 23.375C27.4048 23.375 27.125 23.6548 27.125 24C27.125 24.3452 27.4048 24.625 27.75 24.625Z"
          fill="#979797"
        />
        <path
          d="M27.75 27.125C28.0952 27.125 28.375 26.8452 28.375 26.5C28.375 26.1548 28.0952 25.875 27.75 25.875C27.4048 25.875 27.125 26.1548 27.125 26.5C27.125 26.8452 27.4048 27.125 27.75 27.125Z"
          fill="#979797"
        />
        <path
          d="M27.75 29.625C28.0952 29.625 28.375 29.3452 28.375 29C28.375 28.6548 28.0952 28.375 27.75 28.375C27.4048 28.375 27.125 28.6548 27.125 29C27.125 29.3452 27.4048 29.625 27.75 29.625Z"
          fill="#979797"
        />
        <path
          d="M30.25 16.5H29V15.875C29 15.5 28.75 15.25 28.375 15.25C28 15.25 27.75 15.5 27.75 15.875V16.5H20.25V15.875C20.25 15.5 20 15.25 19.625 15.25C19.25 15.25 19 15.5 19 15.875V16.5H17.75C16.375 16.5 15.25 17.625 15.25 19V30.25C15.25 31.625 16.375 32.75 17.75 32.75H30.25C31.625 32.75 32.75 31.625 32.75 30.25V19C32.75 17.625 31.625 16.5 30.25 16.5ZM17.75 17.75H19V18.375C19 18.75 19.25 19 19.625 19C20 19 20.25 18.75 20.25 18.375V17.75H27.75V18.375C27.75 18.75 28 19 28.375 19C28.75 19 29 18.75 29 18.375V17.75H30.25C30.9375 17.75 31.5 18.3125 31.5 19V20.25H16.5V19C16.5 18.3125 17.0625 17.75 17.75 17.75ZM30.25 31.5H17.75C17.0625 31.5 16.5 30.9375 16.5 30.25V21.5H31.5V30.25C31.5 30.9375 30.9375 31.5 30.25 31.5Z"
          fill="#979797"
        />
      </svg>
    </SvgIcon>
  );
}
