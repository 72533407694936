import { SvgIcon } from "@mui/material";

export default function PurchaseOrder(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 1H4C3.60218 1 3.22064 1.15804 2.93934 1.43934C2.65804 1.72064 2.5 2.10218 2.5 2.5V14.0065C2.49991 14.1722 2.54099 14.3353 2.61953 14.4811C2.69808 14.627 2.81164 14.7511 2.95 14.8422C3.08837 14.9333 3.24721 14.9886 3.41224 15.0032C3.57727 15.0177 3.74333 14.991 3.8955 14.9255L5.622 14.182L7.5625 15.111C7.70077 15.1765 7.85215 15.2097 8.00515 15.208C8.15816 15.2062 8.30875 15.1696 8.4455 15.101L10.5 14.0605L12.05 14.8445C12.2024 14.9213 12.3719 14.9578 12.5424 14.9506C12.7128 14.9433 12.8786 14.8926 13.024 14.8032C13.1693 14.7138 13.2893 14.5887 13.3727 14.4398C13.456 14.2909 13.4998 14.1231 13.5 13.9525V2.5C13.5 2.10218 13.342 1.72064 13.0607 1.43934C12.7794 1.15804 12.3978 1 12 1ZM12.5 13.9525L10.95 13.1685C10.81 13.0976 10.6552 13.0606 10.4983 13.0606C10.3413 13.0606 10.1865 13.0976 10.0465 13.1685L7.9915 14.209L6.0515 13.2805C5.92299 13.2187 5.78269 13.1851 5.6401 13.1822C5.49751 13.1793 5.35595 13.207 5.225 13.2635L3.5 14.0065V2.5C3.5 2.36739 3.55268 2.24021 3.64645 2.14645C3.74022 2.05268 3.86739 2 4 2H12C12.1326 2 12.2598 2.05268 12.3536 2.14645C12.4473 2.24021 12.5 2.36739 12.5 2.5V13.9535V13.9525Z"
          fill="#8E97A7"
        />
        <path
          d="M10.5 4H5.5C5.36739 4 5.24021 4.05268 5.14645 4.14645C5.05268 4.24021 5 4.36739 5 4.5C5 4.63261 5.05268 4.75979 5.14645 4.85355C5.24021 4.94732 5.36739 5 5.5 5H10.5C10.6326 5 10.7598 4.94732 10.8536 4.85355C10.9473 4.75979 11 4.63261 11 4.5C11 4.36739 10.9473 4.24021 10.8536 4.14645C10.7598 4.05268 10.6326 4 10.5 4Z"
          fill="#8E97A7"
        />
        <path
          d="M10.5 6.5H5.5C5.36739 6.5 5.24021 6.55268 5.14645 6.64645C5.05268 6.74021 5 6.86739 5 7C5 7.13261 5.05268 7.25979 5.14645 7.35355C5.24021 7.44732 5.36739 7.5 5.5 7.5H10.5C10.6326 7.5 10.7598 7.44732 10.8536 7.35355C10.9473 7.25979 11 7.13261 11 7C11 6.86739 10.9473 6.74021 10.8536 6.64645C10.7598 6.55268 10.6326 6.5 10.5 6.5Z"
          fill="#8E97A7"
        />
        <path
          d="M8.3125 9H5.5C5.36739 9 5.24021 9.05268 5.14645 9.14645C5.05268 9.24021 5 9.36739 5 9.5C5 9.63261 5.05268 9.75979 5.14645 9.85355C5.24021 9.94732 5.36739 10 5.5 10H8.3125C8.44511 10 8.57229 9.94732 8.66605 9.85355C8.75982 9.75979 8.8125 9.63261 8.8125 9.5C8.8125 9.36739 8.75982 9.24021 8.66605 9.14645C8.57229 9.05268 8.44511 9 8.3125 9Z"
          fill="#8E97A7"
        />
      </svg>
    </SvgIcon>
  );
}
