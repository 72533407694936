import { SvgIcon } from "@mui/material";

export default function MenuClientDetail(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        style={{ width: "20px" }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.64501 1.73091C3.67052 1.62205 2.66178 1.62205 1.6873 1.73091C1.45051 1.75736 1.26378 1.94476 1.23715 2.17236C1.12077 3.16681 1.12077 4.17143 1.23715 5.16588C1.26378 5.39348 1.45051 5.58088 1.6873 5.60733C2.66179 5.71619 3.67052 5.71619 4.64501 5.60733C4.8818 5.58088 5.06853 5.39348 5.09516 5.16588C5.21154 4.17143 5.21154 3.16681 5.09516 2.17236C5.06853 1.94476 4.8818 1.75736 4.64501 1.73091ZM1.55958 0.588752C2.61894 0.470416 3.71336 0.470416 4.77273 0.588752C5.53226 0.673599 6.14744 1.27121 6.23728 2.03885C6.36399 3.122 6.36399 4.21623 6.23728 5.29939C6.14744 6.06707 5.53226 6.66461 4.77273 6.7495C3.71336 6.8678 2.61894 6.8678 1.55958 6.7495C0.80003 6.66461 0.184892 6.06707 0.0950649 5.29939C-0.0316883 4.21623 -0.0316883 3.122 0.0950649 2.03885C0.184892 1.27121 0.80003 0.673599 1.55958 0.588752Z"
          fill="#8E97A7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.64501 9.39264C3.67052 9.28376 2.66178 9.28376 1.6873 9.39264C1.45051 9.41915 1.26378 9.60648 1.23715 9.83411C1.12077 10.8285 1.12077 11.8332 1.23715 12.8276C1.26378 13.0552 1.45051 13.2426 1.6873 13.2691C2.66179 13.3779 3.67052 13.3779 4.64501 13.2691C4.8818 13.2426 5.06853 13.0552 5.09516 12.8276C5.21154 11.8331 5.21154 10.8285 5.09516 9.83411C5.06853 9.60648 4.8818 9.41915 4.64501 9.39264ZM1.55958 8.2505C2.61894 8.1322 3.71336 8.1322 4.77273 8.2505C5.53226 8.33539 6.14744 8.93293 6.23728 9.70056C6.36399 10.7838 6.36399 11.878 6.23728 12.9611C6.14744 13.7288 5.53226 14.3264 4.77273 14.4113C3.71336 14.5296 2.61894 14.5296 1.55958 14.4113C0.80003 14.3264 0.184892 13.7288 0.0950649 12.9611C-0.0316883 11.878 -0.0316883 10.7838 0.0950649 9.70056C0.184892 8.93293 0.80003 8.33539 1.55958 8.2505Z"
          fill="#8E97A7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3105 1.73091C11.3361 1.62205 10.3273 1.62205 9.3528 1.73091C9.116 1.75736 8.92926 1.94476 8.90266 2.17236C8.7863 3.16681 8.7863 4.17143 8.90266 5.16588C8.92926 5.39348 9.116 5.58088 9.3528 5.60733C10.3273 5.71619 11.3361 5.71619 12.3105 5.60733C12.5473 5.58088 12.7341 5.39348 12.7607 5.16588C12.877 4.17143 12.877 3.16681 12.7607 2.17236C12.7341 1.94476 12.5473 1.75736 12.3105 1.73091ZM9.22509 0.588752C10.2845 0.470416 11.3789 0.470416 12.4383 0.588752C13.1978 0.673599 13.813 1.27121 13.9028 2.03885C14.0295 3.122 14.0295 4.21623 13.9028 5.29939C13.813 6.06707 13.1978 6.66461 12.4383 6.7495C11.3789 6.8678 10.2845 6.8678 9.22509 6.7495C8.46556 6.66461 7.85039 6.06707 7.76062 5.29939C7.63383 4.21623 7.63383 3.122 7.76062 2.03885C7.85039 1.27121 8.46556 0.673599 9.22509 0.588752Z"
          fill="#8E97A7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3105 9.39264C11.3361 9.28376 10.3273 9.28376 9.3528 9.39264C9.116 9.41915 8.92926 9.60648 8.90266 9.83411C8.7863 10.8285 8.7863 11.8332 8.90266 12.8276C8.92926 13.0552 9.116 13.2426 9.3528 13.2691C10.3273 13.3779 11.3361 13.3779 12.3105 13.2691C12.5473 13.2426 12.7341 13.0552 12.7607 12.8276C12.877 11.8331 12.877 10.8285 12.7607 9.83411C12.7341 9.60648 12.5473 9.41915 12.3105 9.39264ZM9.22509 8.2505C10.2845 8.1322 11.3789 8.1322 12.4383 8.2505C13.1978 8.33539 13.813 8.93293 13.9028 9.70056C14.0295 10.7838 14.0295 11.878 13.9028 12.9611C13.813 13.7288 13.1978 14.3264 12.4383 14.4113C11.3789 14.5296 10.2845 14.5296 9.22509 14.4113C8.46556 14.3264 7.85039 13.7288 7.76062 12.9611C7.63383 11.878 7.63383 10.7838 7.76062 9.70056C7.85039 8.93293 8.46556 8.33539 9.22509 8.2505Z"
          fill="#8E97A7"
        />
      </svg>
    </SvgIcon>
  );
}
